import { clsx } from 'clsx';

type RadioAttributes = Omit<JSX.IntrinsicElements['input'], 'type' | 'name'> & {
  label: string;
};

type Props = {
  name: string;
  segments:
    | [RadioAttributes, RadioAttributes]
    | [RadioAttributes, RadioAttributes, RadioAttributes];
  className?: string | undefined;
};

export const SegmentedControl = ({ className, name, segments }: Props) => (
  <div
    className={clsx('SegmentedControl', className, {
      'SegmentedControl--twoSegments': segments.length === 2,
      'SegmentedControl--threeSegments': segments.length === 3,
    })}
  >
    {segments.map(({ label, ...attr }, index) => (
      <label
        key={`SegmentedControl=${name}-${index}`}
        className="SegmentedControl-segment"
      >
        <input
          {...attr}
          type="radio"
          name={name}
          className={`SegmentedControl-input SegmentedControl-input${index}`}
        />
        <span className="SegmentedControl-label">{label}</span>
      </label>
    ))}
  </div>
);
