import { clsx } from 'clsx';
import { useEffect, useRef } from 'react';

type Props = Omit<JSX.IntrinsicElements['input'], 'type'> & {
  label: string;
  indeterminate?: boolean;
  readonly?: boolean;
};

export const Checkbox = ({
  className,
  disabled,
  indeterminate,
  label,
  readonly,
  ...attr
}: Props) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!checkboxRef.current) return;
    checkboxRef.current.indeterminate = !!indeterminate;
  }, [checkboxRef, indeterminate]);

  return (
    <label className={clsx('Checkbox', className)}>
      {readonly && <input type="hidden" name={attr.name} value={attr.value} />}
      <input
        type="checkbox"
        {...attr}
        ref={checkboxRef}
        disabled={disabled || readonly}
        className="Checkbox-input"
      />
      <span className="Checkbox-label">{label}</span>
    </label>
  );
};
