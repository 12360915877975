import { clsx } from 'clsx';
import { forwardRef } from 'react';

type Props = Omit<JSX.IntrinsicElements['textarea'], 'ref'>;

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  function Textarea(props, ref) {
    const { autoCapitalize, autoComplete, autoCorrect, className, ...attr } =
      props;
    return (
      <textarea
        {...attr}
        ref={ref}
        autoCapitalize={autoCapitalize ?? 'off'}
        autoComplete={autoComplete ?? 'off'}
        autoCorrect={autoCorrect ?? 'off'}
        className={clsx('Textarea', className)}
      />
    );
  },
);
