import { clsx } from 'clsx';

type Props = {
  errorMessages: (string | undefined)[];
  className?: string | undefined;
};

export const FormErrorMessages = ({ className, errorMessages }: Props) => {
  const messages = errorMessages.filter(Boolean);
  if (messages.length === 0) {
    return null;
  }
  return (
    <ul className={clsx('FormErrorMessages', className)}>
      {messages.map((message, index) => (
        <li key={`error-message-${index}`}>{message}</li>
      ))}
    </ul>
  );
};
