import { clsx } from 'clsx';
import { useRef, useState } from 'react';

type Props = JSX.IntrinsicElements['input'] & {
  className?: string | undefined;
};

export const PasswordInput = (props: Props) => {
  const { autoCapitalize, autoComplete, autoCorrect, className, ...attr } =
    props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const handleClick = () => {
    setVisible(!visible);
    inputRef.current?.focus();
  };

  return (
    <label className={clsx('PasswordInput', className)}>
      <input
        {...attr}
        ref={inputRef}
        type={visible ? 'text' : 'password'}
        autoCapitalize={autoCapitalize ?? 'off'}
        autoComplete={autoComplete ?? 'off'}
        autoCorrect={autoCorrect ?? 'off'}
        className="PasswordInput-input"
      />
      <button
        type="button"
        className={clsx('PasswordInput-button', {
          'is-visible': visible,
        })}
        onClick={handleClick}
        tabIndex={-1}
      />
    </label>
  );
};
