import { clsx } from 'clsx';
import { NumberInput } from 'app/components/common/form';
import type { RefObject } from 'react';

type Props = {
  zipCode1: Omit<JSX.IntrinsicElements['input'], 'type' | 'ref'> & {
    ref: RefObject<HTMLInputElement>;
  };
  zipCode2: Omit<JSX.IntrinsicElements['input'], 'type' | 'ref'> & {
    ref: RefObject<HTMLInputElement>;
  };
  className?: string | undefined;
};

export const ZipCode = ({ className, zipCode1, zipCode2 }: Props) => (
  <div className={clsx('ZipCode', className)}>
    <NumberInput {...zipCode1} className="ZipCode-code ZipCode--code1" />
    <NumberInput {...zipCode2} className="ZipCode-code ZipCode--code2" />
  </div>
);
