import { clsx } from 'clsx';
import { forwardRef } from 'react';

type Props = Omit<JSX.IntrinsicElements['select'], 'ref'> & {
  className?: string | undefined;
};

export const Select = forwardRef<HTMLSelectElement, Props>(function Select(
  props,
  ref,
) {
  const { className, ...attr } = props;
  return <select {...attr} ref={ref} className={clsx('Select', className)} />;
});
