import { clsx } from 'clsx';
import { forwardRef } from 'react';

type Props = Omit<JSX.IntrinsicElements['input'], 'ref'>;

export const TextInput = forwardRef<HTMLInputElement, Props>(function TextInput(
  props,
  ref,
) {
  const { autoCapitalize, autoComplete, autoCorrect, className, ...attr } =
    props;
  return (
    <input
      {...attr}
      ref={ref}
      autoCapitalize={autoCapitalize ?? 'off'}
      autoComplete={autoComplete ?? 'off'}
      autoCorrect={autoCorrect ?? 'off'}
      className={clsx('TextInput', className)}
    />
  );
});
