import { clsx } from 'clsx';
import { forwardRef } from 'react';

type Props = Omit<JSX.IntrinsicElements['input'], 'type'> & {
  className?: string | undefined;
};

export const Toggle = forwardRef<HTMLInputElement, Props>(function Toggle(
  props,
  ref,
) {
  const { className, ...attr } = props;
  return (
    <label className={clsx('Toggle', className)}>
      <input {...attr} ref={ref} type="checkbox" className="Toggle-input" />
    </label>
  );
});
