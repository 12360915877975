import { clsx } from 'clsx';
import { forwardRef } from 'react';

type Props = Omit<JSX.IntrinsicElements['input'], 'ref'>;

export const NumberInput = forwardRef<HTMLInputElement, Props>(
  function NumberInput(props, ref) {
    const {
      autoCapitalize,
      autoComplete,
      autoCorrect,
      className,
      onChange,
      onKeyDown,
      ...attr
    } = props;
    return (
      <input
        {...attr}
        ref={ref}
        autoCapitalize={autoCapitalize ?? 'off'}
        autoComplete={autoComplete ?? 'off'}
        autoCorrect={autoCorrect ?? 'off'}
        className={clsx('TextInput', className)}
        onKeyDown={(e) => {
          if (
            !/[0-9]/.test(e.key) &&
            e.key !== 'Tab' &&
            e.key !== 'Enter' &&
            e.key !== 'Backspace'
          ) {
            e.preventDefault();
          }
          if (onKeyDown) onKeyDown(e);
        }}
        onChange={(e) => {
          const target = e.target;
          target.value = target.value.trim().normalize('NFKC');
          if (onChange) onChange(e);
        }}
      />
    );
  },
);
