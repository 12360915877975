import { clsx } from 'clsx';
import type { ReactNode } from 'react';

type Props = {
  rows: {
    children: ReactNode;
    label?: string | undefined;
    className?: string | undefined;
    isError?: boolean | undefined;
    isOptional?: boolean | undefined;
  }[];
  heading?: string | undefined;
  description?: ReactNode | undefined;
  footer?: ReactNode;
  className?: string | undefined;
};

export const TableView = ({
  className,
  description,
  footer,
  heading,
  rows,
}: Props) => {
  const isSingleField = rows.length === 1 && rows[0]?.label === undefined;

  return (
    <section
      className={clsx('TableView', className, {
        'TableView--single': isSingleField,
      })}
    >
      {heading && <h2 className="TableView-heading">{heading}</h2>}
      {description && (
        <div className="TableView-description">{description}</div>
      )}
      <div className="TableView-table">
        {rows.map((row, index) => (
          <label key={`TableView-row-${index}`} className="TableView-row">
            <div
              className={clsx('TableView-fieldSet', {
                'is-error': row.isError,
                'is-required': !row.isOptional,
              })}
            >
              {row.label && (
                <span className="TableView-label">{row.label}</span>
              )}
              <div className="TableView-fields">{row.children}</div>
            </div>
          </label>
        ))}
      </div>
      {footer && <div className="TableView-footer">{footer}</div>}
    </section>
  );
};
