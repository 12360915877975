import { clsx } from 'clsx';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  heading: string;
  className?: string | undefined;
  error?: string | undefined;
  longHint?: ReactNode;
  htmlFor?: string;
  required?: boolean;
};

export const FieldSet = ({
  children,
  className,
  error,
  heading,
  htmlFor,
  longHint,
  required,
}: Props) => (
  <section className={clsx('FieldSet', className, { 'is-required': required })}>
    <h2 className="FieldSet-heading">
      {htmlFor ? <label htmlFor={htmlFor}>{heading}</label> : heading}
      {required && (
        <sup className="FieldSet-required">
          {process.env.LOCALE === 'ja' ? '※' : '*'}
        </sup>
      )}
    </h2>
    <div className={clsx('FieldSet-field', { 'is-error': error })}>
      {children}
    </div>
    {error && (
      <p className={clsx('FieldSet-hint', { 'is-error': error })}>{error}</p>
    )}
    {longHint && <div className="FieldSet-longHint">{longHint}</div>}
  </section>
);
