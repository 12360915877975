import { clsx } from 'clsx';

type Props = Omit<JSX.IntrinsicElements['input'], 'type'> & {
  label: string;
  readonly?: boolean;
};

export const Radio = ({
  className,
  disabled,
  label,
  readonly,
  ...attr
}: Props) => (
  <label className={clsx('Radio', className)}>
    {readonly && <input type="hidden" name={attr.name} value={attr.value} />}
    <input
      type="radio"
      {...attr}
      disabled={disabled || readonly}
      className="Radio-input"
    />
    <span className="Radio-label">{label}</span>
  </label>
);
