import { clsx } from 'clsx';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';

const SIMPLE_DATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

type Props = Omit<JSX.IntrinsicElements['input'], 'ref' | 'defaultValue'> & {
  name: string;
  defaultValue?: string | undefined;
};

export const DateField = forwardRef<HTMLInputElement, Props>(function DateField(
  props,
  ref,
) {
  const {
    autoComplete,
    autoCorrect,
    className,
    defaultValue,
    name,
    placeholder,
  } = props;
  const placeholders =
    placeholder && placeholder.match(SIMPLE_DATE_REGEX)
      ? placeholder.split('-')
      : undefined;

  const [value, setValue] = useState<string | undefined>(defaultValue);
  const [year, setYear] = useState<string | undefined>(undefined);
  const [month, setMonth] = useState<string | undefined>(undefined);
  const [date, setDate] = useState<string | undefined>(undefined);

  const handleChangeYear = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      target.value = target.value.trim().normalize('NFKC');
      if (target.value === year) return;
      setYear(target.value);
    },
    [year],
  );

  const handleChangeMonth = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      target.value = target.value.trim().normalize('NFKC');
      if (target.value === month) return;
      setMonth(target.value.padStart(2, '0'));
    },
    [month],
  );

  const handleChangeDate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      target.value = target.value.trim().normalize('NFKC');
      if (target.value === date) return;
      setDate(target.value.padStart(2, '0'));
    },
    [date],
  );

  useEffect(() => {
    if (!defaultValue || !defaultValue.match(SIMPLE_DATE_REGEX)) return;
    const [yyyy, mm, dd] = defaultValue.split('-');
    setYear(yyyy);
    setMonth(mm);
    setDate(dd);
  }, [defaultValue]);

  useEffect(() => {
    if (!year || !month || !date) return;
    setValue(`${year}-${month}-${date}`);
  }, [year, month, date]);

  return (
    <div className={clsx('DateField', className)}>
      <input
        defaultValue={year}
        onChange={handleChangeYear}
        placeholder={placeholders && placeholders[0]}
        autoComplete={autoComplete || 'off'}
        autoCorrect={autoCorrect || 'off'}
        maxLength={4}
      />
      <input
        defaultValue={month}
        onChange={handleChangeMonth}
        placeholder={placeholders && placeholders[1]}
        autoComplete={autoComplete || 'off'}
        autoCorrect={autoCorrect || 'off'}
        maxLength={2}
      />
      <input
        defaultValue={date}
        onChange={handleChangeDate}
        placeholder={placeholders && placeholders[2]}
        autoComplete={autoComplete || 'off'}
        autoCorrect={autoCorrect || 'off'}
        maxLength={2}
      />
      <input
        type="hidden"
        ref={ref}
        name={name}
        defaultValue={value}
        className="DateField-input"
      />
    </div>
  );
});
